<template>
  <div style="width: 100%">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
//import wx from "weixin-js-sdk";
//import * as qywx from "jwxwork"
import _axios from 'axios';
import layer from "layer";

const wx = window.wx;
import qs from 'qs'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      title: 'Hello World',
      appId: '',
      timestamp: '',
      nonceStr: '',
      signature: '',
      agency: {
        corpId: '',
        agentId: '',
        timestamp: '',
        nonceStr: '',
        signature: '',
      },
      payType: false,
      payMoney: 600,
      baseUrl: 'https://pay.diandianpeiwan.com/',
      //baseUrl: 'http://localhost:13524/',
    }
  },
  methods: {
    scanCode() {
      let that = this;
      try {
        //动态获取当前页面的url地址
        //将url作为参数传入接口，将接口数据解构出来用data对象接受
        if (that.appId.length === 0) return;
        //可以将接口中数据打印出来看
        wx.config({
          beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 在测试没问题之后，关闭debug模式，防止弹窗
          appId: that.appId, // 必填，企业微信的corpID
          timestamp: that.timestamp, // 必填，生成签名的时间戳
          nonceStr: that.nonceStr, // 必填，生成签名的随机串
          signature: that.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ["createExternalPayment"] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        })

      } catch (error) {
        console.log(error)
        console.log("失败了：" + JSON.stringify(error));
      }
      wx.ready(function (res) {
        that.title = "微信加载成功1";
        console.log("成功了1：" + JSON.stringify(res));
        wx.checkJsApi({
          jsApiList: ['agentConfig', 'createExternalPayment'],
          success: res => {
            console.log('成功了2', res);
            that.title = "API检查成功" + JSON.stringify(that.agency);
            wx.agentConfig({
              corpid: that.agency.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: that.agency.agentId, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: that.agency.timestamp, // 必填，生成签名的时间戳
              nonceStr: that.agency.nonceStr, // 必填，生成签名的随机串
              signature: that.agency.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ['selectExternalContact', 'createExternalPayment'], //必填，传入需要使用的接口名称
              success: function (res) {
                console.log("成功了3：" + res);
                that.title = "企业微信收款校验成功";
                that.payType = true;
                // 回调
                that.paySomething();
              },
              fail: function (res) {
                if (res.errMsg.indexOf('function not exist') > -1) {
                  console.log('请在企业微信中打开该页面')
                }
              }
            });
          }
        });


      })
      wx.error(function (res) {
        //config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        let result = res.resultStr// eslint-disable-line no-unused-vars
        console.log("ready失败！" + result)
      })


    },
    paySomething() {
      const that = this;
      if (that.payType) {
        wx.invoke('createExternalPayment', {
          paymentType: 1,  //收款方式
          totalFee: 300,  //收款金额，单位为分
          description: '可乐一罐' //收款说明
        }, function (res) {
          that.title = "拉起支付" + JSON.stringify(res);
          if (res.err_msg == "createExternalPayment:ok") {
            let paymentId = res.paymentId; //收款项目id
            console.log(paymentId);
            that.title = "拉起微信支付成功" + paymentId;
          } else {
            //错误处理
          }
        });
      } else {
        layer.msg("初始化错误，请联系管理员");
      }
    },
    async getSignStr() {
      let that = this;
      let currentUrl = window.location.href;
      let dz_url = currentUrl.split('#')[0];
      let query = qs.stringify({
        url: dz_url,
      })
      const response = await _axios.post(that.baseUrl + "jva/qywx/common/getSignStr", query);
      that.appId = response.data.appId;
      that.timestamp = response.data.timestamp;
      that.nonceStr = response.data.nonceStr;
      that.signature = response.data.signature;
      if (response.data.appId.length > 0) {
        const responseTwo = await _axios.post(that.baseUrl + "jva/qywx/common/getSignStrAgency", query);
        that.agency.corpId = responseTwo.data.appId;
        that.agency.agentId = responseTwo.data.agentId;
        that.agency.timestamp = responseTwo.data.timestamp;
        that.agency.nonceStr = responseTwo.data.nonceStr;
        that.agency.signature = responseTwo.data.signature;
        if (that.agency.corpId.length > 0) {
          setTimeout(function () {

// 延迟执行的代码
            that.scanCode();
          }, 1500);

        }


      }


    }
  },
  created() {

    this.getSignStr();

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
